@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.content) {
  display: flex;
  padding: $p-4;
  gap: $p-4;
}

:local(.card){
  margin-left: 0px !important;
}

:local(.card):hover {
  cursor: pointer;
  -webkit-box-shadow:
    0 1px 3px 0 $colorEarlGray,
    0 0 0 1px $colorSilverSurfer !important;
  box-shadow:
    0 1px 3px 0 $colorEarlGray,
    0 0 0 1px $colorSilverSurfer !important;
}
