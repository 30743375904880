@import 'src/labstep-web/scss/libs/_functions.scss';
@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.container) {
  display: flex;
  flex-direction: column;
  max-height: none !important;
}

:global {
  :local(.editable) {
    flex-grow: 1;

    .ProseMirror-selectednode {
      &.inline,
      &.block {
        box-shadow: 0 0 0 2px $colorLabstepBlue;
      }
    }

    .ProseMirror .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 20;
      background-color: #adf;
      pointer-events: none;
    }
    .ProseMirror.resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
    /* Give selected cells a blue overlay */
    .ProseMirror .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .selectedCell {
      border-color: rgb(0, 101, 255);
      background-color: rgb(232, 242, 255);
    }
  }
}
:local(.editorContainer) {
  display: flex;
  flex-grow: 1;
  position: relative;
}

:local(.innerEditorContainer) {
  position: relative;
  display: flex;
  overflow: auto;
  flex-grow: 1;
}

:global {
  :local(.prosemirrorStyles) {
    flex-grow: 1;
    overflow: auto;

    p {
      max-width: 1000px;
    }

    .ProseMirror .tableWrapper {
      overflow-x: auto;

      table {
        border-spacing: 0;
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        overflow: hidden;
      }
      td,
      th {
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
      }

      th,
      td {
        border: 1px solid black;
        padding: 2px 4px;
        min-width: 80px;
        word-wrap: break-word;
        min-height: 80px;
        text-align: left;
        border-style: solid;
        position: relative;
        vertical-align: top;

        p {
          margin-bottom: 0px;
        }
      }
    }

    .inline-comment {
      background: rgba(255, 212, 0, 0.14);
      border-bottom: 2px solid rgb(255, 212, 0);
    }

    code {
      background-color: rgba(0, 0, 0, 0.1);
    }

    img {
      max-width: 400px;
    }

    .image-container {
      width: 100%;
    }

    li > p {
      margin-bottom: 0px;
    }

    .inline {
      display: inline-block;
      background-color: white;
    }

    .block {
      margin: 3px 0px;
    }

    .fullWidth {
      display: block;
    }
  }
}

:global {
  :local(.editor) {
    flex-grow: 1;
    background-color: white;
    min-height: 100%;
    border-radius: 5px;
    height: max-content;
    white-space: pre-wrap;

    /* Fix table bug */
    overflow-x: hidden;

    .prose-mirror-editor > div,
    #prose-mirror-editor > div {
      min-height: 100%;
      padding-bottom: 200px;
    }

    &:focus {
      outline: none;
      box-shadow: 0px 4px 20px 3px $colorSailBoat;
    }

    .ProseMirror {
      &:focus {
        outline: none;
      }
    }
  }
}

:local(.right) {
  flex-shrink: 0;
  overflow: hidden;
}

:local(.leftMenuWrapper) {
  position: absolute;
  left: 0px;
  @include mobile {
    position: fixed;
    left: 10px;
    bottom: 20px;
  }
}

:local(.nameContainer) {
  margin-bottom: 20px;
}

:local(.topMenu) {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 15px;
  padding: 5px 10px;
  border: 1px solid $colorArctic;
  border-radius: 5px;
  z-index: 2;
  margin-bottom: 6px;

  > div:first-child {
    flex-grow: 1;

    @include mobile {
      flex-wrap: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-left: 15px;
  }
}
