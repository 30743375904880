@import 'src/labstep-web/scss/libs/_vars.scss';

:local(.text) {
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    :local(.preview) {
      opacity: 1;
    }
  }
}

:local(.preview) {
  opacity: 0;

  position: absolute;
  right: 5px;
  top: 5px;
}
