@import 'src/labstep-web/scss/libs/_vars.scss';
@import 'src/labstep-web/scss/libs/_functions.scss';

:local(.linksContainer) {
  display: flex;
  height: 40px;
  background-color: white;
  overflow-x: auto;
  flex-shrink: 0;
  white-space: nowrap;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    display: none;
  }
}

:local(.noBottomMargin) {
  margin-bottom: 0px;
}

:local(.fluid) {
  justify-content: space-around;
}

:global {
  :local(.link) {
    font-family: 'poppins';
    font-size: 13px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
    color: $colorRiverBed;

    &.active {
      color: $colorHyperlink;
      border-bottom: 2px solid $colorHyperlink;
    }
  }
}

:global {
  :local(.inverted) {
    border-bottom: 1px solid $colorArctic;
    border-top: 2px solid white;

    &.active {
      color: $colorHyperlink;
      border-top: 2px solid $colorHyperlink;
      border-bottom: none;
    }
  }
}


:local(.groupsContainer) {
  margin-top: 10px;
}

:local(.barrier) {
  border-bottom: 1px solid $colorArctic;
  flex-grow: 1;
}

:global {
  :local(.stretch) {
    flex-grow: 1;
    text-align: center;
    &.active {
      color: $colorHyperlink;
      border-top: 2px solid white;
      border-bottom: none;
    }
    &:first-child {
      border-right: 1px solid $colorArctic;
    }
  }
}

:local(.container){
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
