/**
 * Labstep
 */

:local(.linkContainer) {
  text-overflow: ellipsis;
  overflow: hidden;

  > div {
    /* prevent viewComponent from spanning the entire cell
    so double clicking around link triggers edit mode */
    display: inline;
  }
}
